(function($) {
    $(() => {

        $('.cover .cover-slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            nextArrow: '.cover .arrow--right',
            prevArrow: '.cover .arrow--left'
        });

        $(window).scroll(function(){
            if($(window).scrollTop() > 0) {
                $('.navbar').addClass('is-scrolled')
            } else {
                $('.navbar').removeClass('is-scrolled')
            }
        });

        $('.dropdown-toggle').mouseover(function(){
            $('.dropdown-menu').addClass('show');
        });

        $('.navbar').mouseleave(function(){
            $('.dropdown-menu').removeClass('show');
        });

        $('.navbar .nav-link:not(.dropdown-toggle)').mouseover(function(){
            $('.dropdown-menu').removeClass('show');
        });

        $('#package').on('change', function () {
            if ($(this).val() === "Prémium csomag") {
                $('#e-learning').prop('checked', true)
            }
            if ($(this).val() === "Teljes csomag") {
                $('#e-learning').prop('checked', false)
            }
        });

        $('.btn').click(function () {
            if ($(this).data('package') !== undefined) {
                $('#package').val($(this).data('package'));
                if ($(this).data('package') === "Prémium csomag") {
                    $('#e-learning').prop('checked', true)
                }
                if ($(this).data('package') === "Teljes csomag") {
                    $('#e-learning').prop('checked', false)
                }
            }
        });

        $('#e-learning').on('change', function () {
            if ($('#package').val() === "Prémium csomag" && !document.getElementById('e-learning').checked) {
                $('#package').val("Teljes csomag");
            }
            if ($('#package').val() === "Teljes csomag" && document.getElementById('e-learning').checked) {
                $('#package').val("Prémium csomag");
            }
        });

        $('a.anchor').click(function() {
            $('html, body').animate({'scrollTop' : $($(this).attr('href')).offset().top - 60},1000);
        });

        $('.people').click(function () {
            $('.people-text').removeClass('d-lg-block');
            $('.people-text').addClass('d-none');
            $($(this).data('text')).addClass('d-lg-block');
            $('.people .image').removeClass('active');
            $(this).children('.image').addClass('active');
        });


        $('.cover .cover-slider').on('afterChange', function() {
            $('#right_arrow_text').html($('.slick-current').next().find('h2').html());
            $('#left_arrow_text').html($('.slick-current').prev().find('h2').html());
        });

	$('a[data-slide]').click(function(e) {
	   var slideno = $(this).data('slide');
	   $('.cover .cover-slider').slick('slickGoTo', slideno - 1);
	});

    });
})(jQuery);
